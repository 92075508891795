import { IDropdownOption } from '@fluentui/react'

export const editLivePlanConfirmationTitle = 'Confirmation'
export const editLivePlanConfirmationText = `You are editing an active plan. Clicking Next will immediately disable the plan you are editing and create a new plan in its place with changes you have made.\n\nThis plan will only be active after the plan is submitted and Live. \n\n\nDo you want to Continue?`
export const editLivePlanConfirmationButtonText = 'Yes'
export const editLivePlanCancelButtonText = 'Cancel'

export const recipientOptions: IDropdownOption[] = [
  {
    key: 'First Party',
    text: 'First Party'
  },
  {
    key: 'Third Party',
    text: 'Third Party'
  }
]

export const DigitalAutorzOptions = {
  EAuth: 'EAuth',
  TxtAuth: 'SMS'
}
