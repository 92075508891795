import axios, { Method } from 'axios'
import { chunk, trim, uniqBy } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { IApiOptions } from '../shared/contracts/IApiOptions'
import { IOdataResult } from '../shared/contracts/IOdataResult'
import {
  isNotNullOrEmpty,
  isNotNullOrFalse,
  isNotNullOrUndefined
} from '../shared/guards'
import { escapeFilterString } from './odata'
import { IOdataRequest } from './odata.types'
import { escapeAndEncodeQuery, tokenizeQuery } from './search'

export interface IProfile {
  name: string
  keyContact?: boolean
  type: 'contact' | 'org'
  id?: string
  contact?: IContact
  org?: IOrganization
}

export interface IDynamicsApiResult<T> {
  '@odata.context'?: string
  '@odata.nextLink'?: string
  '@odata.count'?: number
  value?: T[]
}

export interface IBusinessUnit {
  name?: string
  businessunitid: string
  _parentbusinessunitid_value?: string
  parentbusinessunitid?: IBusinessUnit
  children?: IBusinessUnit[]
  emailaddress?: string
  rcm_hierarchycode?: string
}

export const getBusinessUnits = (
  options: IApiOptions,
  rootBusinessUnitId?: string
) => {
  const url = [
    `${options.apiRoot}/api/data/v9.1/businessunits`,
    rootBusinessUnitId && `(${rootBusinessUnitId})`,
    '/Microsoft.Dynamics.CRM.RetrieveBusinessHierarchyBusinessUnit()?',
    '$select=name,businessunitid,_parentbusinessunitid_value,rcm_hierarchycode'
  ]
    .filter(Boolean)
    .join('')

  return axios
    .get<IDynamicsApiResult<IBusinessUnit>>(`${url}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data.value)
}

export interface ISystemUserRole {
  name: string
  roleid: string
}

export interface ISystemUser {
  systemuserid?: string
  title?: string
  fullname?: string
  azureactivedirectoryobjectid?: string
  domainname?: string
  employeeid?: string
  isdisabled?: string
  lastname?: string
  jobtitle?: string
  organizationid?: string
  rcm_AdvisorRep_SystemUser?: IAdvisorRep[]
  rcm_AdvisorManager_Manager_SystemUser?: IAdvisorManager[]
  rcm_AdvisorAttributes_Advisor_SystemUser?: IAdvisorAttributes[]
  rcm_AdvisorRep_PersonalRepFor_SystemUser?: IAdvisorRep[]
  businessunitid?: IBusinessUnit
  _businessunitid_value?: string
  systemuserroles_association?: ISystemUserRole[]
  ownerid?: string
}

export interface IAdvisorManager {
  rcm_isprimarysigner?: boolean
  rcm_isprincipal?: boolean
  rcm_advisormanagerid?: string
  _rcm_businessunit_value?: string
}

export interface IAdvisorRep {
  rep_id: any
  rcm_name?: string
  rcm_repid: string
  rcm_advisorrepid?: string
  _rcm_owningteam_value?: string
  _rcm_personalrepfor_value?: string
  rcm_AdvisorRepPoolSplits_PoolRep_rcm_Advi?: IRepSplits[]
  rcm_PersonalRepFor?: ISystemUser
  rcm_OwningTeam?: IDynamicsTeam
}

export interface IAdvisorAttributes {
  rcm_wealthscapeinvestorid?: string
  rcm_crdnumber?: string
  rcm_advisorattributesid?: string
  _rcm_primaryrepcode_value?: string
}

export const getSystemUsersInBusinessUnit = (
  options: IApiOptions,
  businessUnitIds?: string[]
) => {
  const url = [
    `${options.apiRoot}/api/data/v9.1/systemusers?`,
    businessUnitIds &&
      `$filter=Microsoft.Dynamics.CRM.In(PropertyName='businessunitid',PropertyValues=[${businessUnitIds
        .map((x) => `'${x}'`)
        .join(',')}])`,
    '&$select=systemuserid,title,fullname,windowsliveid,azureactivedirectoryobjectid,' +
      'internalemailaddress,employeeid,isdisabled,lastname,jobtitle,_businessunitid_value',
    '&$expand=rcm_AdvisorRep_SystemUser($select=rcm_name,rcm_repid),rcm_AdvisorRep_PersonalRepFor_SystemUser($select=rcm_name,rcm_repid),rcm_AdvisorAttributes_Advisor_SystemUser'
  ]
    .filter(Boolean)
    .join('')

  return axios
    .get<IDynamicsApiResult<ISystemUser>>(`${url}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data.value)
}

export interface IContact {
  firstname: string
  lastname: string
  emailaddress1: string
  contactid: string
  fullname: string
  middlename?: string
  suffix?: string
  modifiedon: string
  'rpm_dateofbirth@OData.Community.Display.V1.FormattedValue'?: string
  mobilephone?: string
  telephone1?: string
  telephone2?: string
  jobtitle?: string
  'rcm_contacttype@OData.Community.Display.V1.FormattedValue'?: string
  'rcm_taxidtype@OData.Community.Display.V1.FormattedValue'?: string
  nickname?: string
  rcm_secureid?: string
  address1_composite?: string
  rpm_headofhousehold?: boolean
  'familystatuscode@OData.Community.Display.V1.FormattedValue'?: string
  'gendercode@OData.Community.Display.V1.FormattedValue'?: string
  rcm_rockcodbpartyid?: string
  _aka_householdid_value?: string
  rpm_employer?: string
  '_rcm_primaryadvisor_value@OData.Community.Display.V1.FormattedValue'?: string
  '_rcm_primaryclientassociate_value@OData.Community.Display.V1.FormattedValue'?: string
  _rcm_primaryadvisor_value?: string
  _rcm_primaryclientassociate_value?: string
  rcm_clientsegment?: number
  'rcm_clientsegment@OData.Community.Display.V1.FormattedValue'?: string
  rpm_networth?: number
  'rpm_networth@OData.Community.Display.V1.FormattedValue'?: string
  rcm_qualifiedpurchaser?: boolean
  'rcm_qualifiedpurchaser@OData.Community.Display.V1.FormattedValue'?: string
  rcm_accreditedinvestor?: boolean
  'rcm_accreditedinvestor@OData.Community.Display.V1.FormattedValue'?: string
}

export interface IOrg {
  accountid?: string
  name?: string
}

export const findContactsByEmailAddress = (
  options: IApiOptions,
  emailAddresses: string[]
): Promise<IContact[] | undefined> => {
  if (!emailAddresses?.length) {
    return Promise.resolve(undefined)
  }
  const url = [
    `${options.apiRoot}/api/data/v9.1/contacts?`,
    `$filter=statecode eq 0 and (${emailAddresses
      .map((x) =>
        ['emailaddress1', 'emailaddress2']
          .map((prop) => `${prop} eq '${encodeURIComponent(x)}'`)
          .join(' or ')
      )
      .join(' or ')})`,
    '&$select=firstname,lastname,emailaddress1,contactid,fullname,modifiedon'
  ]
    .filter(Boolean)
    .join('')

  return axios
    .get<IDynamicsApiResult<IContact>>(`${url}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data.value)
}

export interface IWhoAmIResponse {
  BusinessUnitId: string
  UserId: string
  OrganizationId: string
}

export const whoAmI = (
  options: IApiOptions
): Promise<IWhoAmIResponse | undefined> => {
  const url = `${options.apiRoot}/api/data/v9.1/WhoAmI`

  return axios
    .get<IWhoAmIResponse>(url, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export const getSystemUsers = (
  options: IApiOptions,
  request: IOdataRequest
) => {
  const url = `${options.apiRoot}/api/data/v9.1/systemusers`

  const {
    top,
    filters,
    orderby,
    select = [
      'systemuserid',
      'title',
      'fullname',
      'azureactivedirectoryobjectid',
      'domainname',
      'employeeid',
      'isdisabled',
      'lastname',
      'firstname',
      'jobtitle'
    ],
    expand,
    search,
    searchFields,
    count
  } = request

  const allFilters = [...(filters || [])]

  if (search && searchFields?.length) {
    const tokens = tokenizeQuery(search)
      .map(escapeFilterString)
      .map(escapeAndEncodeQuery)
      .map(trim)
      .filter(isNotNullOrEmpty)

    const searchFilters = search
      ? tokens.map(
          (token) =>
            `(${searchFields
              .map((field) => `contains(${field}, '${token}')`)
              .join(' or ')})`
        )
      : []

    allFilters.push(...searchFilters)
  }

  const query = [
    count && '$count=true',
    !!select?.length && `$select=${select.join(',')}`,
    !!expand?.length && `$expand=${expand.join(',')}`,
    !!orderby?.length &&
      `$orderby=${orderby
        .map(
          ({ dataPath, direction }) =>
            `${dataPath}${direction ? ` ${direction}` : ''}`
        )
        .join(',')}`,
    !!allFilters?.length && `$filter=${allFilters.join(' and ')}`
  ]
    .filter(isNotNullOrFalse)
    .join('&')

  return axios
    .get<IOdataResult<ISystemUser>>(`${url}?${query}`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`,
        ...(top && { Prefer: `odata.maxpagesize=${top}` })
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export const getSystemUserDetail = (options: IApiOptions, id: string) => {
  const url = `${options.apiRoot}/api/data/v9.1/systemusers(${id})`
  const query = [
    '$select=systemuserid,title,fullname,azureactivedirectoryobjectid,domainname,employeeid,isdisabled,lastname,jobtitle,organizationid,_businessunitid_value',
    `$expand=${[
      'rcm_AdvisorRep_SystemUser($select=rcm_name,rcm_repid,_rcm_owningteam_value,_rcm_personalrepfor_value)',
      'rcm_AdvisorRep_PersonalRepFor_SystemUser($select=rcm_name,rcm_repid,_rcm_owningteam_value,_rcm_personalrepfor_value)',
      'rcm_AdvisorManager_Manager_SystemUser($select=rcm_isprimarysigner,rcm_isprincipal,rcm_advisormanagerid,statuscode,_rcm_businessunit_value)',
      'rcm_AdvisorAttributes_Advisor_SystemUser($select=rcm_wealthscapeinvestorid,rcm_crdnumber,rcm_advisorattributesid,_rcm_primaryrepcode_value)',
      'businessunitid($select=name,_parentbusinessunitid_value;$expand=parentbusinessunitid($select=name;$expand=parentbusinessunitid($select=name)))',
      'systemuserroles_association($select=name)'
    ].join(',')}`
  ].join('&')

  return axios
    .get<ISystemUser>(`${url}?${query}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export const getHierarchyForReps = (options: IApiOptions, repIds: string[]) => {
  const url = `${options.apiRoot}/api/data/v9.1/rcm_advisorreps`
  const query = [
    '$select=rcm_name,rcm_repid',
    `$filter=Microsoft.Dynamics.CRM.In(PropertyName='rcm_repid',PropertyValues=[${repIds
      .map((x) => `'${x}'`)
      .join(',')}])`,
    '$expand=rcm_OwningTeam($select=name;$expand=businessunitid($select=name,emailaddress;$expand=parentbusinessunitid($select=name,emailaddress;$expand=parentbusinessunitid($select=name,emailaddress))))'
  ].join('&')

  return axios
    .get<IDynamicsApiResult<IDynamicsCdmAdvisorRep>>(`${url}?${query}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data.value)
}

export const getManagersForBusinessUnits = (
  options: IApiOptions,
  businessUnitIds: string[]
) => {
  const url = `${options.apiRoot}/api/data/v9.1/systemusers`
  const query = [
    '$select=fullname,domainname',
    `$filter=rcm_AdvisorManager_Manager_SystemUser/any(user: Microsoft.Dynamics.CRM.In(PropertyName='rcm_businessunit',PropertyValues=[${businessUnitIds
      .map((x) => `'${x}'`)
      .join(',')}]))`
  ].join('&')

  return axios
    .get<IDynamicsApiResult<ISystemUser>>(`${url}?${query}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data.value)
}

export const getBusinessUnitHierarchy = async (
  options: IApiOptions,
  ids: string[]
) => {
  const results = await Promise.all(
    ids.map((id) => {
      const url =
        `${options.apiRoot}/api/data/v9.1/businessunits(${id})/` +
        `Microsoft.Dynamics.CRM.RetrieveBusinessHierarchyBusinessUnit()?$select=name,businessunitid,_parentbusinessunitid_value`

      return axios
        .get<IDynamicsApiResult<IBusinessUnit>>(url, {
          headers: { Authorization: `Bearer ${options.accessToken}` },
          cancelToken: options.cancelToken
        })
        .then((x) => x.data.value)
    })
  )

  return uniqBy(
    results.filter(isNotNullOrUndefined).flat(),
    (x) => x.businessunitid
  )
}

export interface IDynamicsCdmAdvisorRep {
  rcm_advisorrepid?: string
  rcm_advisorid?: string
  rcm_repid?: string
  rcm_name?: string
  rcm_OwningTeam?: IDynamicsTeam
  rcm_PersonalRepFor?: ISystemUser
  rcm_AdvisorRep_SystemUser?: ISystemUser[]
}

export const getRepCodeDetails = (options: IApiOptions, repCode: string) => {
  const url = `${options.apiRoot}/api/data/v9.1/rcm_advisorreps`
  const query = [
    '$top=1',
    `$filter=rcm_repid eq '${repCode}'`,
    '$select=rcm_advisorrepid,rcm_repid,rcm_name',
    `$expand=${[
      `rcm_OwningTeam(${[
        '$select=_businessunitid_value,name,teamid',
        `$expand=${[
          'businessunitid($select=name;$expand=parentbusinessunitid($select=name;$expand=parentbusinessunitid($select=name)))'
        ].join(',')}`
      ].join(';')})`,
      'rcm_PersonalRepFor($select=domainname,fullname)'
    ].join(',')}`
  ].join('&')

  return axios
    .get<IDynamicsApiResult<IDynamicsCdmAdvisorRep>>(`${url}?${query}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data?.value?.[0])
}

export const getDynamicsTeamMembers = (
  options: IApiOptions,
  teamid: string
) => {
  const url = `${options.apiRoot}/api/data/v9.1/teams(${teamid})/teammembership_association`

  return axios
    .get<IDynamicsApiResult<ISystemUser>>(
      `${url}?$select=domainname,fullname,azureactivedirectoryobjectid,title&$filter=isdisabled eq false`,
      {
        headers: { Authorization: `Bearer ${options.accessToken}` },
        cancelToken: options.cancelToken
      }
    )
    .then((x) => x.data)
}

export interface IDynamicsDepartment {
  cdm_name?: string
  cdm_departmentnumber?: string
  cdm_departmentid?: string
}

export interface IDynamicsBdaTransaction {
  rcm_description?: string
  rcm_description1?: string
  rcm_description2?: string
  rcm_description3?: string
  rcm_description4?: string
  rcm_description5?: string
  rcm_description6?: string
  rcm_journalnumber?: string
  rcm_account?: string
  rcm_yearclosed?: 100000002
  rcm_totalamount?: number
  rcm_adjustmenttoamount?: number
  rcm_ledgeraccount?: string
  rcm_location?: string
  rcm_accountname?: string
  rcm_date?: string
  rcm_postingtype?: string
  rcm_inorout?: 798960000
  rcm_entity?: string
  rcm_trackercategory?: string
  rcm_trackerdetail?: string
  rcm_advisor?: string
  rcm_businessdevelopmentaccountid?: string
  rcm_Department?: IDynamicsDepartment
  _ownerid_value?: string
  '_ownerid_value@OData.Community.Display.V1.FormattedValue'?: string
  owningbusinessunit?: IBusinessUnit
}

export const getBdaTransactions = (
  options: IApiOptions,
  fromDate?: Date,
  toDate?: Date,
  pageSize?: number
) => {
  const base = `${options.apiRoot}/api/data/v9.1/rcm_businessdevelopmentaccounts`
  const start = fromDate || new Date()
  const end =
    toDate ||
    new Date(Date.UTC(start.getUTCFullYear(), 11, 31, 23, 59, 59, 999))
  const query = [
    '$select=rcm_description,rcm_totalamount,rcm_adjustmenttoamount,rcm_advisor,' +
      'rcm_date,rcm_inorout,rcm_trackercategory,rcm_trackerdetail,_ownerid_value',
    `$filter=rcm_date ge ${start.toISOString()} and rcm_date le ${end.toISOString()} and rcm_inorout eq 798960000`,
    `$expand=${[
      `rcm_Department($select=cdm_name,cdm_departmentnumber)`,
      `owningbusinessunit($select=name)`
    ].join(',')}`
  ].join('&')

  return axios
    .get<IDynamicsApiResult<IDynamicsBdaTransaction>>(`${base}?${query}`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`,
        Prefer: [
          pageSize ? `odata.maxpagesize=${pageSize}` : undefined,
          `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        ]
          .filter(isNotNullOrEmpty)
          .join(', ')
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export interface IDynamicsBdaPayrollExpense {
  rcm_recordcode?: string
  rcm_currentearnings?: number
  rcm_payrolldate?: string
  rcm_trackercategory?: string
  rcm_employeename?: string
  rcm_offcycle?: boolean
  rcm_bdapayrollexpensesid?: string
  rcm_Department?: IDynamicsDepartment
  _ownerid_value?: string
  '_ownerid_value@OData.Community.Display.V1.FormattedValue'?: string
  owningbusinessunit?: IBusinessUnit
}

export const supportCode = 798960001
export const payrollOnlyCode = 798960001

export const getBdaPayrollExpenses = (
  options: IApiOptions,
  fromDate?: Date,
  supportRecordCodes: string[] = [],
  supplementalCompRecordCodes: string[] = [],
  pageSize?: number
) => {
  const base = `${options.apiRoot}/api/data/v9.1/rcm_bdapayrollexpenseses`
  const start = fromDate || new Date()
  const end = new Date(
    Date.UTC(start.getUTCFullYear(), 11, 31, 23, 59, 59, 999)
  )

  const filters = [
    `rcm_payrolldate ge ${start.toISOString()}`,
    `rcm_payrolldate le ${end.toISOString()}`,
    'rcm_currentearnings ne 0',
    `(${[
      `(${[
        `Microsoft.Dynamics.CRM.In(PropertyName='rcm_recordcode',PropertyValues=[${supportRecordCodes
          .map((x) => `'${x}'`)
          .join(',')}])`,
        `rcm_pwaorsupport eq ${supportCode}`
      ].join(' and ')})`,
      `Microsoft.Dynamics.CRM.In(PropertyName='rcm_recordcode',PropertyValues=[${supplementalCompRecordCodes
        .map((x) => `'${x}'`)
        .join(',')}])`,
      `Microsoft.Dynamics.CRM.In(PropertyName='rcm_recordcode',PropertyValues=['BDA Bonus','BDA One Time','BDA Monthly'])`
    ].join(' or ')})`
  ]
  const query = [
    '$select=rcm_recordcode,rcm_currentearnings,rcm_payrolldate,rcm_trackercategory,rcm_employeename,rcm_offcycle,_ownerid_value',
    `$filter=${filters.join(' and ')}`,
    `$expand=${[
      `rcm_Department($select=cdm_name,cdm_departmentnumber)`,
      `owningbusinessunit($select=name)`
    ].join(',')}`
  ].join('&')

  return axios
    .get<IDynamicsApiResult<IDynamicsBdaPayrollExpense>>(`${base}?${query}`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`,
        Prefer: [
          pageSize ? `odata.maxpagesize=${pageSize}` : undefined,
          `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        ]
          .filter(isNotNullOrEmpty)
          .join(', ')
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export interface IDynamicsTeam {
  _businessunitid_value?: string
  name?: string
  teamid?: string
  businessunitid?: IBusinessUnit
  rcm_Team_Department_Team_Team?: IAdvisorTeamProfile[]
}

export interface IAdvisorTeamProfile {
  rcm_advertisedt12revenue?: number
  rcm_startdate?: string
}

export interface IDynamicsEntityWithOwner {
  owninguser?: ISystemUser
  owningteam?: IDynamicsTeam
  ownerid?: { ownerid?: string }
}

export interface IDynamicsBdaDepartmentAllowance
  extends IDynamicsEntityWithOwner {
  rcm_bdateamallowanceid?: string
  rcm_allowance?: number
  rcm_startdate?: string
  rcm_Department?: IDynamicsDepartment
  rcm_allowancetype?: number
  rcm_allowancedisclaimer?: string
  rcm_lastyearremainingallowance?: number
}
export const getBdaDepartmentAllowances = (
  options: IApiOptions,
  fromDate?: Date,
  pageSize?: number
) => {
  const base = `${options.apiRoot}/api/data/v9.1/rcm_bdadepartmentallowances`
  const start = fromDate || new Date()
  const end = new Date(
    Date.UTC(start.getUTCFullYear(), 11, 31, 23, 59, 59, 999)
  )
  const query = [
    '$select=rcm_allowance,rcm_startdate,rcm_allowancetype,rcm_allowancedisclaimer,rcm_lastyearremainingallowance',
    `$filter=rcm_startdate ge ${start.toISOString()} and rcm_startdate le ${end.toISOString()}`,
    `$expand=${[
      `rcm_Department($select=cdm_name,cdm_departmentnumber)`,
      `owninguser($select=fullname,domainname;$expand=businessunitid($select=name;$expand=parentbusinessunitid($select=name;$expand=parentbusinessunitid($select=name))))`,
      `owningteam($select=name;$expand=businessunitid($select=name;$expand=parentbusinessunitid($select=name;$expand=parentbusinessunitid($select=name))))`,
      `ownerid`
    ].join(',')}`
  ].join('&')

  return axios
    .get<IDynamicsApiResult<IDynamicsBdaDepartmentAllowance>>(
      `${base}?${query}`,
      {
        headers: {
          Authorization: `Bearer ${options.accessToken}`,
          ...(pageSize && { Prefer: `odata.maxpagesize=${pageSize}` })
        },
        cancelToken: options.cancelToken
      }
    )
    .then((x) => x.data)
}

export const findRoleByName = (
  options: IApiOptions,
  roleName: string,
  businessUnitId: string
) => {
  const base = `${options.apiRoot}/api/data/v9.1/roles`
  const query = [
    '$select=roleid,name,_businessunitid_value',
    `$filter=_businessunitid_value eq ${businessUnitId} and name eq '${roleName}'`,
    '$top=1'
  ].join('&')
  return axios
    .get<IDynamicsApiResult<ISystemUserRole>>(`${base}?${query}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export const addRoleToUser = (
  options: IApiOptions,
  systemuserid: string,
  roleid: string
) => {
  const url = `${options.apiRoot}/api/data/v9.1/systemusers(${systemuserid})/systemuserroles_association/$ref`
  return axios
    .post<any>(
      url,
      {
        '@odata.id': `${options.apiRoot}/api/data/v9.1/roles(${roleid})`
      },
      {
        headers: { Authorization: `Bearer ${options.accessToken}` },
        cancelToken: options.cancelToken
      }
    )
    .then((x) => x.data)
}

export const removeRoleFromUser = (
  options: IApiOptions,
  systemuserid: string,
  roleid: string
) => {
  const url = `${options.apiRoot}/api/data/v9.1/systemusers(${systemuserid})/systemuserroles_association(${roleid})/$ref`
  return axios
    .delete<any>(url, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export interface ICdsBatchRequestItem {
  method: Method
  url: string
  payload?: any
  headers?: Record<string, string>
}

export const executeCdsBatchRequest = async (
  options: IApiOptions,
  requests: ICdsBatchRequestItem[]
) => {
  const { accessToken, apiRoot } = options
  const newline = '\r\n'
  const boundary = `batch_${uuidv4()}`
  const chunks = chunk(requests, 1000)

  const parts = chunks.map((chunk) =>
    chunk.map((x) =>
      [
        `${x.method} ${x.url} HTTP/1.1`,
        ...Object.entries(x.headers || {}).map(
          ([key, value]) => `${key}: ${value}`
        ),
        x.payload && 'Content-Type: application/json',
        x.payload && '',
        x.payload && `${JSON.stringify(x.payload)}`
      ]
        .filter((x) => x != null)
        .join(newline)
    )
  )

  const payloads = parts.map((part) => {
    return [
      `--${boundary}`,
      'Content-Type: application/http',
      'Content-Transfer-Encoding:binary',
      '',
      ...[
        part.join(
          [
            '',
            '',
            `--${boundary}`,
            'Content-Type: application/http',
            'Content-Transfer-Encoding:binary',
            '',
            ''
          ].join(newline)
        )
      ],
      '',
      `--${boundary}--`,
      ''
    ].join(newline)
  })

  const responses = await Promise.all(
    payloads.map(async (payload) => {
      return axios.post(`${apiRoot}/api/data/v9.1/$batch`, payload, {
        headers: {
          'Content-Type': `multipart/mixed;boundary=${boundary}`,
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'OData-MaxVersion': '4.0',
          'OData-Version': '4.0'
        }
      })
    })
  )

  return responses
}

export interface IAttachment {
  filename: string
  body: string
  documentbody: string
  mimetype: string
  activitymimeattachmentid: string
  filesize: number
}

export interface IActivity {
  subject?: string
  description?: string
  'modifiedon@OData.Community.Display.V1.FormattedValue'?: string
  '_modifiedby_value@OData.Community.Display.V1.FormattedValue'?: string
  'activitytypecode@OData.Community.Display.V1.FormattedValue'?: string
  'statecode@OData.Community.Display.V1.FormattedValue'?: string
  'createdon@OData.Community.Display.V1.FormattedValue'?: string
  '_ownerid_value@OData.Community.Display.V1.FormattedValue'?: string
  'scheduledend@OData.Community.Display.V1.FormattedValue'?: string
  '_createdby_value@OData.Community.Display.V1.FormattedValue'?: string
  '_regardingobjectid_value@OData.Community.Display.V1.FormattedValue'?: string
  'actualend@OData.Community.Display.V1.FormattedValue'?: string
  activity_pointer_activity_mime_attachment?: [IAttachment]
  scheduledend?: string
  activityid?: string
  _regardingobjectid_value?: string
  statuscode?: number
  _ownerid_value?: string
  prioritycode?: number
  rcm_sendemail?: boolean
  actualdurationminutes?: number
}

export const getActivity = (
  options: IApiOptions,
  contactid: string
): Promise<IActivity[] | undefined> => {
  const url = `${options.apiRoot}/api/data/v9.1/contacts(${contactid})/Contact_ActivityPointers`
  const query = [
    '$select=subject,description,modifiedon,_modifiedby_value,_ownerid_value,statecode,createdon,scheduledend',
    '$expand=activity_pointer_activity_mime_attachment($select=filename,activitymimeattachmentid, filesize)'
  ].join('&')
  return axios
    .get<IDynamicsApiResult<IActivity>>(`${url}?${query}`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`,
        Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data.value)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export interface INotes {
  subject?: string
  notetext?: string
  'modifiedon@OData.Community.Display.V1.FormattedValue'?: string
  '_modifiedby_value@OData.Community.Display.V1.FormattedValue'?: string
  'createdon@OData.Community.Display.V1.FormattedValue'?: string
  '_ownerid_value@OData.Community.Display.V1.FormattedValue'?: string
  '_createdby_value@OData.Community.Display.V1.FormattedValue'?: string
  filename?: string
  annotationid?: string
  objectid_contact?: IContact
  objectid_account?: IOrganization
  documentbody?: string
  isdocument?: boolean
  mimetype?: string
}

export interface IOrganization {
  name?: string
  'ram_investortype@OData.Community.Display.V1.FormattedValue'?: string
  'rcm_taxidtype@OData.Community.Display.V1.FormattedValue'?: string
  rcm_taxid?: string
  address1_composite?: string
  accountid?: string
  rcm_rockcodbpartyid?: string
  telephone1?: string
  emailaddress1?: string
  ram_dba?: string
  'industrycode@OData.Community.Display.V1.FormattedValue'?: string
  '_primarycontactid_value@OData.Community.Display.V1.FormattedValue'?: string
}

export const getNotes = (
  options: IApiOptions,
  contactid: string
): Promise<INotes[] | undefined> => {
  const url = `${options.apiRoot}/api/data/v9.1/contacts(${contactid})/Contact_Annotation`
  const query =
    '$select=subject,notetext,modifiedon,_modifiedby_value,_ownerid_value,createdon,filename,annotationid'
  return axios
    .get<IDynamicsApiResult<INotes>>(`${url}?${query}`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`,
        Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data.value)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export type IActivityAndNotes = IActivity & INotes

export const getAttachment = (
  options: IApiOptions,
  attachmentId: string
): Promise<IAttachment | undefined> => {
  const url = `${options.apiRoot}/api/data/v9.1/activitymimeattachments(${attachmentId})`
  const query = '$select=filename,body,mimetype'
  return axios
    .get<IAttachment>(`${url}?${query}`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export const getNoteAttachment = (
  options: IApiOptions,
  attachmentId: string
): Promise<IAttachment | undefined> => {
  const url = `${options.apiRoot}/api/data/v9.1/annotations(${attachmentId})`
  const query = '$select=filename,documentbody,mimetype'
  return axios
    .get<IAttachment>(`${url}?${query}`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export interface IRepSplits {
  rcm_percentage: number
  rcm_calc_personalrepid: string
  rcm_calc_personalrepname: string
  rcm_calc_poolrepid: string
  rcm_calc_poolrepname: string
}

export type AccountLinkingValidationRequestType = 'existing' | 'new' | 'delink'
export interface IAccountLinkingRequest {
  rcm_cliendid?: string
  rcm_accountstoadd?: string
  rcm_accountstoremove?: string
  rcm_verificationdate?: string
  rcm_verificationtime?: string
  rcm_client?: string
  rcm_clientmethod?: string
  rcm_requesttype?: AccountLinkingValidationRequestType
  rcm_mfaphone?: string
  rcm_newclient?: string
  rcm_newclientemailid?: string
  rcm_newclientrole?: string
  rcm_notes?: string
  rcm_status?: number
  createdon?: string
  rcm_accountlinkingrequestid?: string
  modifiedon?: string
  _modifiedby_value?: string
  'rcm_status@OData.Community.Display.V1.FormattedValue'?: string
  '_modifiedby_value@OData.Community.Display.V1.FormattedValue'?: string
  '_createdby_value@OData.Community.Display.V1.FormattedValue'?: string
  owningbusinessunit?: IBusinessUnit
  _owninguser_value?: string
  owninguser?: ISystemUser
  rcm_partyid?: string
}

export const createAccountLinkingRequest = async (
  options: IApiOptions,
  request?: IAccountLinkingRequest
) => {
  const headers = {
    'OData-Version': '4.0',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'OData-MaxVersion': '4.0',
    Prefer: 'return=representation',
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios.post<IAccountLinkingRequest>(
    `${options.apiRoot}/api/data/v9.1/rcm_accountlinkingrequests`,
    request,
    { headers: headers }
  )
}

export const getAccountLinkingRequests = (
  options: IApiOptions,
  request: IOdataRequest
) => {
  const { cancelToken, accessToken } = options

  const url = `${options.apiRoot}/api/data/v9.1/rcm_accountlinkingrequests`

  const { top, filters, orderby, expand, search, searchFields, count } = request

  const allFilters = [...(filters || [])]

  if (search && searchFields?.length) {
    const tokens = tokenizeQuery(search)
      .map(escapeFilterString)
      .map(escapeAndEncodeQuery)
      .map(trim)
      .filter(isNotNullOrEmpty)

    const searchFilters = search
      ? tokens.map(
          (token) =>
            `(${searchFields
              .map((field) => `contains(${field}, '${token}')`)
              .join(' or ')})`
        )
      : []

    allFilters.push(...searchFilters)
  }

  const query = [
    count && '$count=true',
    `$expand=${[
      ...(expand || []),
      `owninguser($select=fullname;$expand=businessunitid($select=name;$expand=parentbusinessunitid($select=name;$expand=parentbusinessunitid($select=name))))`
    ]}`,
    !!orderby?.length &&
      `$orderby=${orderby
        .map(
          ({ dataPath, direction }) =>
            `${dataPath}${direction ? ` ${direction}` : ''}`
        )
        .join(',')}`,
    !!allFilters?.length && `$filter=${allFilters.join(' and ')}`
  ]
    .filter(isNotNullOrFalse)
    .join('&')
  const topSelected = top ? `odata.maxpagesize=${top}` : undefined
  return axios
    .get<IOdataResult<IAccountLinkingRequest>>(`${url}?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Prefer: `${topSelected}, odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
}

export const getAccountLinkingRequest = (options: IApiOptions, id: string) => {
  const { apiRoot, cancelToken, accessToken } = options
  return axios
    .get<IAccountLinkingRequest>(
      `${apiRoot}/api/data/v9.1/rcm_accountlinkingrequests(${id})`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
}

export enum AccountLinkingRequestStatusEnum {
  REQUESTED = 798960000,
  REJECTED = 798960001,
  COMPLETED = 798960002,
  APPROVED = 798960003,
  PROCESSING = 798960004,
  FAILED = 798960005
}

export const updateAccountLinkingRequest = (
  options: IApiOptions,
  request?: IAccountLinkingRequest
) => {
  const headers = {
    'OData-Version': '4.0',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'OData-MaxVersion': '4.0',
    Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`,
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .patch<IAccountLinkingRequest>(
      `${options.apiRoot}/api/data/v9.1/rcm_accountlinkingrequests(${request?.rcm_accountlinkingrequestid}) `,
      request,
      { headers: headers }
    )
    .then((res) => res.data)
}
export interface IMarginRateRequest {
  rcm_householdid?: string
  rcm_accounts?: string
  rcm_justification?: string
  rcm_rate?: number
  rcm_ratetype?: string
  rcm_status?: number
  rcm_marginraterequestid?: string
  createdon?: string
  modifiedon?: string
  rcm_householdname?: string
  _ownerid_value?: string
  'rcm_status@OData.Community.Display.V1.FormattedValue'?: string
  '_modifiedby_value@OData.Community.Display.V1.FormattedValue'?: string
  '_createdby_value@OData.Community.Display.V1.FormattedValue'?: string
}
export enum MarginRateRequestStatusEnum {
  REQUESTED = 798960000,
  REJECTED = 798960001,
  COMPLETED = 798960002,
  APPROVED = 798960003,
  PROCESSING = 798960004,
  FAILED = 798960005
}
export const createMarginRateRequest = (
  options: IApiOptions,
  request?: IMarginRateRequest
) => {
  const headers = {
    'OData-Version': '4.0',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'OData-MaxVersion': '4.0',
    Prefer: 'return=representation',
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .post<IMarginRateRequest>(
      `${options.apiRoot}/api/data/v9.1/rcm_marginraterequests`,
      request,
      {
        headers: headers
      }
    )
    .then((res) => res.data)
}
export const getMarginRateRequests = (
  options: IApiOptions,
  request: IOdataRequest
) => {
  const { cancelToken, accessToken } = options

  const url = `${options.apiRoot}/api/data/v9.1/rcm_marginraterequests`

  const { top, filters, orderby, expand, search, searchFields, count } = request

  const allFilters = [...(filters || [])]

  if (search && searchFields?.length) {
    const tokens = tokenizeQuery(search)
      .map(escapeFilterString)
      .map(escapeAndEncodeQuery)
      .map(trim)
      .filter(isNotNullOrEmpty)

    const searchFilters = search
      ? tokens.map(
          (token) =>
            `(${searchFields
              .map((field) => `contains(${field}, '${token}')`)
              .join(' or ')})`
        )
      : []

    allFilters.push(...searchFilters)
  }

  const query = [
    count && '$count=true',
    !!expand?.length && `$expand=${expand.join(',')}`,
    !!orderby?.length &&
      `$orderby=${orderby
        .map(
          ({ dataPath, direction }) =>
            `${dataPath}${direction ? ` ${direction}` : ''}`
        )
        .join(',')}`,
    !!allFilters?.length && `$filter=${allFilters.join(' and ')}`
  ]
    .filter(isNotNullOrFalse)
    .join('&')
  const topSelected = top ? `odata.maxpagesize=${top}` : undefined
  return axios
    .get<IOdataResult<IMarginRateRequest>>(`${url}?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Prefer: `${topSelected}, odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
}
export const getMarginRateRequest = (options: IApiOptions, id: string) => {
  const { apiRoot, cancelToken, accessToken } = options
  return axios
    .get<IMarginRateRequest>(
      `${apiRoot}/api/data/v9.1/rcm_marginraterequests(${id})`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
}

export const deleteMarginRateRequest = (options: IApiOptions, id: string) => {
  const { apiRoot, cancelToken, accessToken } = options
  return axios
    .delete(`${apiRoot}/api/data/v9.1/rcm_marginraterequests(${id})`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
}

export const updateMarginRateRequest = (
  options: IApiOptions,
  request?: IMarginRateRequest
) => {
  const headers = {
    'OData-Version': '4.0',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'OData-MaxVersion': '4.0',
    Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`,
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .patch<IMarginRateRequest>(
      `${options.apiRoot}/api/data/v9.1/rcm_marginraterequests(${request?.rcm_marginraterequestid}) `,
      request,
      { headers: headers }
    )
    .then((res) => res.data)
}

export interface IBrokerageFirm {
  rcm_name?: string
}
export const getBrokerageFirms = (options: IApiOptions) => {
  const { apiRoot, cancelToken, accessToken } = options
  return axios
    .get<IOdataResult<IBrokerageFirm>>(
      `${apiRoot}/api/data/v9.0/rcm_brokeragefirmses`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
}

export const getDynamicsTeams = (options: IApiOptions, search?: string) => {
  const url = [
    `${options.apiRoot}/api/data/v9.1/teams?`,
    [
      '$filter=rcm_Team_Department_Team_Team/any()',
      search && `contains(name, '${search}')`
    ]
      .filter(Boolean)
      .join(' and '),
    '&$expand=rcm_Team_Department_Team_Team'
  ]
    .filter(Boolean)
    .join('')

  return axios
    .get<IDynamicsApiResult<IDynamicsTeam>>(`${url}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data.value)
}

export const getDynamicsReps = (options: IApiOptions, search?: string) => {
  const url = [
    `${options.apiRoot}/api/data/v9.1/rcm_advisorreps?`,
    [
      '$filter=_rcm_personalrepfor_value ne null',
      search &&
        `contains(rcm_name, '${search}') or contains(rcm_repid, '${search}')`
    ]
      .filter(Boolean)
      .join(' and '),
    '&$top=20'
  ]
    .filter(Boolean)
    .join('')

  return axios
    .get<IDynamicsApiResult<IAdvisorRep>>(`${url}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data.value)
}
export interface IDynamicsConsolidation {
  '_rcm_primaryadvisor_value@OData.Community.Display.V1.FormattedValue'?: string
  '_rcm_primaryclientassociate_value@OData.Community.Display.V1.FormattedValue'?: string
  '_owningbusinessunit_value@OData.Community.Display.V1.FormattedValue'?: string
  rcm_householdid?: string
  aka_name?: string
  aka_rollupid?: string
  _rcm_primaryadvisor_value?: string
  _rcm_primaryclientassociate_value?: string
  _owningbusinessunit_value?: string
  rcm_primaryadvisor?: RcmPrimaryadvisorAndCA
  rcm_primaryclientassociate?: RcmPrimaryadvisorAndCA
}
export interface RcmPrimaryadvisorAndCA {
  azureactivedirectoryobjectid?: string
  systemuserid?: string
  ownerid?: string
}
